<script setup lang="ts">
import type { NavItem } from '@nuxt/content/dist/runtime/types'

const navigation = inject<NavItem[]>('navigation', [])

const { header } = useAppConfig()
</script>

<template>
  <UHeader>
    <template #logo>
    	<img src="/assets/images/logo-35x35-primary.png" alt="JuicyLlama Development Agency" />
    </template>

    <template #right>

      <UColorModeButton v-if="header?.colorMode" />

      <template v-if="header?.links">
        <UButton
          v-for="(link, index) of header.links"
          :key="index"
          v-bind="{ color: 'gray', variant: 'ghost', ...link }"
        />
      </template>
    </template>

    <!-- <template #panel>
      <UNavigationTree default-open :multiple="false" :links="mapContentNavigation(navigation)" />
    </template> -->
  </UHeader>
</template>
